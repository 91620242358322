import React, { useState } from "react";
import { Card } from "react-bootstrap";
import moment from "moment/moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import User from "../../../images/DummyUser.png";

function PatientCard({
  patientName,
  patientImage,
  patientIdNumber,
  patientSex,
  patientId,
  gender,
  appointmentStatus,
  lastAppointmentDate,
  appointmentType,
  email,
  phoneNumber,
  countryCode
}) {
  const navigate = useHistory();

  function goToDetails() {
    navigate.push(`/patients/${patientId}?type=${appointmentType}`);
  }

  function capitalizeFirstLetterOfFirstWord(phrase) {
    if (phrase?.length === 0) return phrase;
    return phrase?.charAt(0).toUpperCase() + phrase?.slice(1);
  }

  return (
    <div className="appointment-card-container">
      <Card
        className="appointment-card row cursor-pointer animate__animated animate__fadeIn"
        onClick={goToDetails}
      >
        <div className="col-3">
          <label htmlFor="" className="appointment-card_label">
            Patient
          </label>
          <div className="d-flex align-items-center">
            <img
              src={
                patientImage
                  ? process.env.REACT_APP_BUCKET_BASE_URL + patientImage
                  : User
              }
              alt=""
              className="appointment-card_img"
            />
            <span className="appointment-card_field">
              {capitalizeFirstLetterOfFirstWord(patientName)}
            </span>
          </div>
        </div>

        <div className="col-2">
          <label htmlFor="" className="appointment-card_label">
            Patient Id
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field">{patientIdNumber}</span>
          </div>
        </div>

        <div className="col-3">
          <label htmlFor="" className="appointment-card_label">
            Phone Number
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field mr-1">+{countryCode}</span><span className="appointment-card_field">{phoneNumber}</span>
          </div>
        </div>

        <div className="col-2">
          <label htmlFor="" className="appointment-card_label">
            Gender
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field">{gender}</span>
          </div>
        </div>
        {/* <div className="col-2">
        <label htmlFor="" className="appointment-card_label">
          Status
        </label>
        <div className="d-flex align-items-center">
          <span className="appointment-card_field border p-1 px-2 rounded">
            {appointmentStatus}
          </span>
        </div>
      </div> */}
      </Card>
    </div>
  );
}

export default PatientCard;
