import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Button, Nav, Tab } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  addAdditionalMedicalRecord,
  addMedicalRecord,
  getMedicalRecordAPI,
} from "../../services/DashboardAsyncFunctions";
import { useHistory } from "react-router-dom";

function VideoCallFormFill({
  onHide,
  isVideoCall,
  show,
  appointmentId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState("medicalInfo");
  const history = useHistory();

  const [callData, setCallData] = useState({
    other: "",
    vitals: "",
    chiefOfComplaint: "",
    hpi: "",
    physicalExam: "",
    diagnosis: "",
    assessment: "",
    investigation: "",
    medications: "",
    plan: "",
  });

  const [additionalData, setAdditionalData] = useState({});

  const handleArrayChange = (field, value) => {
    if (value.trim() !== "" && !additionalData[field].includes(value.trim())) {
      setAdditionalData((prev) => ({
        ...prev,
        [field]: [...prev[field], value.trim()],
      }));
    }
  };

  const handleKeyPress = (e, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleArrayChange(field, e.target.value);
      e.target.value = "";
    }
  };

  const removeItem = (field, index) => {
    setAdditionalData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  async function submit() {
    const data = {
      maritalStatus: additionalData?.maritalStatus,
      lifeStyle: additionalData?.lifeStyle,
      surgeryHistory: additionalData?.surgeryHistory,
      familyHistory: additionalData?.familyHistory,
      pastMedicalHistory: additionalData?.pastMedicalHistory,
      allergies: additionalData?.allergies,
      medication: additionalData?.medication,
      immunization: additionalData?.immunization,
    };
    if (key === "medicalInfo") {
      try {
        setIsLoading(true);
        const response = await addMedicalRecord({
          ...callData,
          appointmentId,
        });
        if (
          response.status &&
          localStorage.getItem("video-call-show") == "true"
        ) {
          toast.success("The medical record is added!");
        } else if (
          response.status &&
          localStorage.getItem("video-call-show") == "false"
        ) {
          toast.success("The medical record is added!");
          history.push(`/`);
        }
      } catch (error) {
        console.log(error, "error");
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        const response = await addAdditionalMedicalRecord({
          ...data,
          appointmentId,
        });
        if (
          response.status &&
          localStorage.getItem("video-call-show") == "true"
        ) {
          toast.success("The medical record is added!");
        } else if (
          response.status &&
          localStorage.getItem("video-call-show") == "false"
        ) {
          toast.success("The medical record is added!");
          history.push(`/`);
        }
      } catch (error) {
        console.log(error, "error");
      } finally {
        setIsLoading(false);
      }
    }
  }

  const getMedicalRecord = async () => {
    try {
      const response = await getMedicalRecordAPI(appointmentId);
      let medicalRecord;
      let additionalData;
      if (response.data.data.bookingFor === "mySelf") {
        medicalRecord = response.data.data.medicalRecord;
        additionalData = response.data.data.user;
      } else {
        medicalRecord = response.data.data.medicalRecord;
        additionalData = response.data.data.member;
      }
      setCallData({
        other: medicalRecord?.other || "",
        vitals: medicalRecord?.vitals || "",
        chiefOfComplaint: medicalRecord?.chiefOfComplaint || "",
        hpi: medicalRecord?.hpi || "",
        physicalExam: medicalRecord?.physicalExam || "",
        diagnosis: medicalRecord?.diagnosis || "",
        assessment: medicalRecord?.assessment || "",
        investigation: medicalRecord?.investigation || "",
        medications: medicalRecord?.medications || "",
        plan: medicalRecord?.plan || "",
      });
      setAdditionalData(additionalData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMedicalRecord();
  }, [isVideoCall]);

  return (
    <Modal
      className="left-modal"
      backdrop="static"
      onHide={onHide}
      show={show}
      dialogClassName="left-modal-dialog"
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Add Medical Record</h3>
              <Button variant="" className="close" onClick={onHide}>
                <span style={{ fontSize: "30px" }}>&times;</span>
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="medicalInfo">
                  Medical Record
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="additionalInfo">
                  Medical History
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content className="mt-3">
                <Tab.Pane eventKey="medicalInfo">
                  <form className="d-flex align-items-start flex-column gap-2">
                    <div style={{ width: "100%" }}>
                      <h6>Notes</h6>
                      <textarea
                        value={callData.other}
                        onChange={(e) =>
                          setCallData((prev) => ({
                            ...prev,
                            other: e.target.value,
                          }))
                        }
                        className="form-control mr-2"
                        style={{ minHeight: "100px" }}
                        name="other"
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <h6>Vitals</h6>
                      <input
                        type="text"
                        value={callData.vitals}
                        onChange={(e) =>
                          setCallData((prev) => ({
                            ...prev,
                            vitals: e.target.value,
                          }))
                        }
                        className="form-control mr-2"
                        name="vitals"
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <h6>Chief of complaint</h6>
                      <input
                        type="text"
                        value={callData.chiefOfComplaint}
                        onChange={(e) =>
                          setCallData((prev) => ({
                            ...prev,
                            chiefOfComplaint: e.target.value,
                          }))
                        }
                        className="form-control mr-2"
                        name="chiefOfComplaint"
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <h6>HPI</h6>
                      <input
                        type="text"
                        value={callData.hpi}
                        onChange={(e) =>
                          setCallData((prev) => ({
                            ...prev,
                            hpi: e.target.value,
                          }))
                        }
                        className="form-control mr-2"
                        name="hpi"
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <h6>Physical Exam</h6>
                      <input
                        type="text"
                        value={callData.physicalExam}
                        onChange={(e) =>
                          setCallData((prev) => ({
                            ...prev,
                            physicalExam: e.target.value,
                          }))
                        }
                        className="form-control mr-2"
                        name="physicalExam"
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <h6>Diagnosis</h6>
                      <input
                        type="text"
                        value={callData.diagnosis}
                        onChange={(e) =>
                          setCallData((prev) => ({
                            ...prev,
                            diagnosis: e.target.value,
                          }))
                        }
                        className="form-control mr-2"
                        name="diagnosis"
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <h6>Assessment</h6>
                      <input
                        type="text"
                        value={callData.assessment}
                        onChange={(e) =>
                          setCallData((prev) => ({
                            ...prev,
                            assessment: e.target.value,
                          }))
                        }
                        className="form-control mr-2"
                        name="assessment"
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <h6>Investigation</h6>
                      <input
                        type="text"
                        value={callData.investigation}
                        onChange={(e) =>
                          setCallData((prev) => ({
                            ...prev,
                            investigation: e.target.value,
                          }))
                        }
                        className="form-control mr-2"
                        name="investigation"
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <h6>Medications</h6>
                      <input
                        type="text"
                        value={callData.medications}
                        onChange={(e) =>
                          setCallData((prev) => ({
                            ...prev,
                            medications: e.target.value,
                          }))
                        }
                        className="form-control mr-2"
                        name="medications"
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <h6>Plan</h6>
                      <input
                        type="text"
                        value={callData.plan}
                        onChange={(e) =>
                          setCallData((prev) => ({
                            ...prev,
                            plan: e.target.value,
                          }))
                        }
                        className="form-control mr-2"
                        name="Plan"
                      />
                    </div>
                  </form>
                </Tab.Pane>

                <Tab.Pane eventKey="additionalInfo">
                  <form className="d-flex flex-column gap-2">
                    <div style={{ width: "100%" }}>
                      <h6>Marital Status</h6>
                      <select
                        className="form-control"
                        value={additionalData?.maritalStatus}
                        onChange={(e) =>
                          setAdditionalData((prev) => ({
                            ...prev,
                            maritalStatus: e.target.value,
                          }))
                        }
                      >
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                      </select>
                    </div>

                    {[
                      "lifeStyle",
                      "surgeryHistory",
                      "familyHistory",
                      "pastMedicalHistory",
                      "allergies",
                      "medication",
                      "immunization",
                    ]?.map((field) => (
                      <div key={field} style={{ width: "100%" }}>
                        <h6>
                          {field
                            .replace(/([A-Z])/g, " $1")
                            .trim()
                            .replace(/^./, (char) => char.toUpperCase())}
                        </h6>

                        <input
                          type="text"
                          placeholder="Press Enter to add"
                          onKeyPress={(e) => handleKeyPress(e, field)}
                          className="form-control"
                        />
                        <div className="mt-2 gap-2">
                          {additionalData?.[field]?.map((item, index) => (
                            <span
                              key={index}
                              className="badge bg-secondary me-2 mr-2 text-white"
                            >
                              {item}{" "}
                              <span
                                type="button"
                                className="btn-close ms-1 ml-2"
                                onClick={() => removeItem(field, index)}
                              >
                                &times;
                              </span>
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </form>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>

          <Card.Footer>
            <button
              className="btn btn-primary w-100"
              onClick={submit}
              disabled={isLoading}
            >
              {isLoading ? "Loading" : "Add Data"}
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default VideoCallFormFill;
