import React, { useEffect, useLayoutEffect, useState } from "react";
import ConversationList from "../components/chat/ConversationList";
import MessagesList from "../components/chat/MessagesList";
import socketServices from "../../services/SocketServices";
import { useSelector } from "react-redux";
import {
  getChatMessageList,
  getProfileFetch,
} from "../../services/DashboardAsyncFunctions";
import "../../css/chat.css";
import { Tab, Tabs } from "react-bootstrap";

function Chat() {
  const [messageList, setMessageList] = useState([]);
  const selectedConversation = useSelector(
    (state) => state.session.selectedConversationDetails
  );
  const [isChatFetching, setIsChatFetching] = useState(false);
  const [screenWidth] = useState(window.innerWidth);
  const [key, setKey] = useState("messages");

  async function initialConnect() {
    setMessageList([]);
    if (selectedConversation) {
      setTimeout(() => {
        socketServices.emit("joinConversation", {
          conversationId: selectedConversation?.conversationId,
          userId: JSON.parse(localStorage.getItem("_anytime_user_")).id,
        });
      }, 500);
    }
    socketServices.on("receiveMessage", (data) => {
      setMessageList((prev) => [...prev, data]);
    });

    socketServices.on("joinedConversation", (data) => {
      getChatMessages(data);
    });

    socketServices.on("leavedConversation", (data) => {
      console.log(data);
    });
  }

  function leaveConversation() {
    if (selectedConversation) {
      socketServices.emit("leaveConversation", {
        conversationId: selectedConversation?.conversationId,
        userId: JSON.parse(localStorage.getItem("_anytime_user_")).id,
      });
    }
    socketServices.removeListener("receiveMessage");
    socketServices.removeListener("joinedConversation");
  }

  async function getChatMessages(conversationId) {
    setIsChatFetching(true);
    try {
      const response = await getChatMessageList(conversationId);
      console.log(response);
      setMessageList(response.data?.data?.chat.reverse());
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsChatFetching(false);
    }
  }

  useEffect(() => {
    initialConnect();
    return () => leaveConversation();
  }, [selectedConversation]);

  useLayoutEffect(() => {
    document.body.classList.add("no-scroll");
    return () => document.body.classList.remove("no-scroll");
  }, []);

  return (
    <div className="chat-page">
      {screenWidth > 500 ? (
        <div className="tabs-container">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="messages" title="Follow-up Chat">
              <div className="conversation-column">
                <ConversationList type="appointment" />
              </div>
            </Tab>
            <Tab eventKey="urgent" title="Urgent care">
              <div className="conversation-column">
                <ConversationList type="chat" />
              </div>
            </Tab>
          </Tabs>
        </div>
      ) : (
        <div className="">
          {!selectedConversation && <div className="tabs-container">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="messages" title="Chat">
              <div className="conversation-column">
                <ConversationList type="appointment" />
              </div>
            </Tab>
            <Tab eventKey="urgent" title="Urgent care">
              <div className="conversation-column">
                <ConversationList type="chat" />
              </div>
            </Tab>
          </Tabs>
        </div>}
        </div>
      )}
      {screenWidth > 500 ? (
        <MessagesList
          messageList={messageList}
          isChatFetching={isChatFetching}
          conversationSelectedTrue={selectedConversation}
        />
      ) : (
        <div className="mobile-placeholder">
          {selectedConversation && (
            <MessagesList
              messageList={messageList}
              isChatFetching={isChatFetching}
              conversationSelectedTrue={selectedConversation}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Chat;
