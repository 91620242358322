import React from "react";
import { Card } from "react-bootstrap";

import { MdLocalPharmacy } from "react-icons/md";

function PharmacyCard({
  name,
  email,
  type,
  location,
  index,
  openingHours,
  distance,
  selectedPharmacies,
  pharmaId,
  handleSelection,
}) {
  return (
    <div
      className={`card-body shadow-sm pointer rounded p-0 pt-2 mb-2 ${
        selectedPharmacies?.includes(pharmaId) && "pharma-card_selected"
      }`}
      onClick={() => handleSelection(pharmaId)}
    >
      <div className="content-container items-list-1">
        <div className="d-flex align-items-center gap-2">
          <span className=" ">#{index + 1}</span>
          <div className="card-icon shadow-sm">
            <MdLocalPharmacy size="30px" />
          </div>
        </div>
        <div className="media-body col-sm-4 col-7 col-xxl-5 px-0">
          <h5 className="mt-0 mb-1">
            <div className="text-black" to="ecom-product-detail">
              {name}
            </div>
          </h5>
          <small className="font-w500">
            {type == "selection" && (
              <span className="text-secondary me-2">
                {openingHours?.startTime} - {openingHours?.closeTime}
              </span>
            )}
            {type == "display" && (
              <div className="text-primary fs-12">
                {email}
              </div>
            )}
          </small>
        </div>
        <div className="media-footer ms-auto col-2 px-0 d-flex align-self-center align-items-center">
          <div className="me-3"></div>
          {type == "selection" && (
            <div>
              <h4 className="mb-0 font-w500 text-black">{distance}</h4>
              <span className="fs-12 text-primary">Distance</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PharmacyCard;
