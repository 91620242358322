import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  dashBoardData,
  getConversationList,
  getDoctorAppointmentListing,
} from "../../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";
import User from "./../../../images/DummyUser.png";
import moment from "moment/moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { selectConversationForChat } from "../../../store/actions/SessionActions";
import { useDispatch } from "react-redux";
import AdminRequest from "../../modal/AdminRequest";
import ReAttactDocu from "../../modal/ReAttactDocu";
import useFCM from "../../../hooks/useFCM";
import {
  getNotificationToken,
  getToggleStatus,
} from "../../../services/AuthService";
import { set } from "react-hook-form";

const Home = () => {
  const { fcmToken } = useFCM();
  const loginType = localStorage.getItem("_anytime_doctor_type_");
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isDashBoardLoading, setIsDashBoardLoading] = useState(false);
  const [chatList, setChatList] = useState(null);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [upcomingAppointments, setUpcomingAppointments] = useState(null);
  const [toggleActive, setToggleActive] = useState(false);
  const [dashboardResult, setDashboardResult] = useState({
    upComingAppointments: 0,
    liveAppointment: 0,
    pastAppointments: 0,
  });
  const [requestType, setRequestType] = useState("Accepted");
  const [reason, setReason] = useState("");
  const [reAttactShow, setReAttactShow] = useState(false);

  async function getUpcomingAppointment() {
    // if (!hasMoreUpcomingData) return;
    setIsLoading(true);
    try {
      let response = await getDoctorAppointmentListing("upComing", 0, 4);
      let updatedList = response.data.data.appointments;
      setUpcomingAppointments(updatedList);
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function getUserChatList() {
    setIsMessageLoading(true);
    try {
      const response = await getConversationList({ page: 0, limit: 10 });
      let updatedList = response.data.data.conversations;
      setChatList(updatedList);
      // totalItems = response.data.data.total;
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    } finally {
      setIsMessageLoading(false);
    }
  }

  async function getDashboardData() {
    setIsDashBoardLoading(true);
    try {
      const response = await dashBoardData();
      let updatedList = response.data.data;
      setDashboardResult(updatedList);
      setRequestType(updatedList?.adminApprove);
      setToggleActive(updatedList?.isOnline);
      if (updatedList?.adminApprove === "Rejected") {
        setReason(updatedList?.reason);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    } finally {
      setIsDashBoardLoading(false);
    }
  }

  useEffect(() => {
    if (loginType == "Doctor") {
      getDashboardData();
      getUpcomingAppointment();
      getUserChatList();
    }
  }, []);

  function goToDetails(appointmentId) {
    navigate.push(`/appointments/${appointmentId}`);
  }

  const pendingData =
    upcomingAppointments &&
    upcomingAppointments.filter((item) => {
      return item.status === "Pending";
    });

  const unReadMessage =
    chatList &&
    chatList.filter((item) => {
      return item.unReadMessage > 0;
    });

  function handleSelectConversation(conversationData) {
    dispatch(
      selectConversationForChat({
        conversationId: conversationData?._id,
        name: conversationData?.receiver?.name,
        Id: conversationData?.receiver?._id,
        image: conversationData?.receiver?.image,
        email: conversationData?.receiver?.email,
      })
    );
    navigate.push(`/chat`);
  }

  const getNotification = async () => {
    const payload = { deviceType: "Web", deviceToken: fcmToken };
    try {
      const notificationResponse = await getNotificationToken(payload);
    } catch (error) {}
  };

  useEffect(() => {
    const token = localStorage.getItem("_anytime_doctor_token_");
    if (fcmToken && token) {
      getNotification();
    }
  }, [fcmToken]);

  const handleToggle = async () => {
    setToggleActive(!toggleActive);
    try {
      const response = await getToggleStatus();
      getDashboardData();
      if (response.data.data?.isOnline) {
        toast.success("Now you are online");
      } else {
        toast.success("Now you are offline");
      }
    } catch (error) {}
  };

  return (
    <>
      <AdminRequest
        show={
          (requestType == "Rejected" || requestType == "Pending") &&
          !reAttactShow
            ? true
            : false
        }
        onHide={() => setRequestType("Accepted")}
        requestType={requestType}
        reason={reason}
        setReAttactShow={() => setReAttactShow(true)}
      />
      <ReAttactDocu
        show={reAttactShow}
        onHide={() => setReAttactShow(false)}
        requestType={requestType}
        getDashboardData={getDashboardData}
        setRequestType={() => setRequestType("Rejected")}
      />
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Dashboard</h2>
        </div>
        <div className="mb-2 mb-md-0 w-100 text-end d-flex justify-content-end align-items-center gap-2">
          <div className="text-black font-w600 fs-14 text-center">Status</div>
          <div
            className={`toggle-switch ${toggleActive ? "active" : ""}`}
            onClick={handleToggle}
          >
            <div className="toggle-circle"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-warning text-warning mr-2">
                  <svg
                    id="icon-customers"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </span>
                <div className="media-body">
                  <h3 className="mb-0 text-black">
                    <CountUp
                      className="ms-0"
                      start={0}
                      end={dashboardResult?.upComingAppointments}
                      duration={5}
                    />
                  </h3>
                  <p className="mb-0">Total Upcoming Appointments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body  p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-danger text-danger mr-2">
                  <svg
                    id="icon-customers"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </span>
                <div className="media-body">
                  <h3 className="mb-0 text-black">
                    <CountUp
                      className="ms-0"
                      start={0}
                      end={dashboardResult?.liveAppointment}
                      duration={5}
                    />
                  </h3>
                  <p className="mb-0">Total Live Appointments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-success text-success mr-2">
                  <svg
                    id="icon-customers"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </span>
                <div className="media-body">
                  <h3 className="mb-0 text-black">
                    <CountUp
                      className="ms-0"
                      start={0}
                      end={dashboardResult?.pastAppointments}
                      duration={5}
                    />
                  </h3>
                  <p className="mb-0">Total Past Appointments</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-xxl-6 col-lg-6">
          <div className="card">
            <div className="card-header  border-0 pb-0">
              <h4 className="card-title">Latest Appointments</h4>
            </div>
            <div className="card-body">
              <PerfectScrollbar
                style={{ height: "370px" }}
                id="DZ_W_Todo1"
                className="widget-media dz-scroll ps ps--active-y"
              >
                <ul className="timeline">
                  {pendingData?.length > 0
                    ? !isLoading &&
                      pendingData?.map((item) => {
                        return (
                          <li
                            onClick={() => goToDetails(item._id)}
                            key={item._id}
                            className="cursor-pointer"
                          >
                            <div className="timeline-panel d-flex align-items-center justify-content-between">
                              <div className="d-flex">
                                <div className="media me-2">
                                  <img
                                    alt="avatar"
                                    width="50"
                                    src={
                                      item.user?.image
                                        ? process.env
                                            .REACT_APP_BUCKET_BASE_URL +
                                          item.user?.image
                                        : User
                                    }
                                  />
                                </div>
                                <div className="media-body ml-4">
                                  <h5 className="mb-1">{item.user.name}</h5>
                                  <small className="d-block">
                                    {moment(item?.timeSlots?.[0]).format(
                                      "DD, MMM yyyy"
                                    )}
                                  </small>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="d-flex align-items-center">
                                  <span className="appointment-card_field border p-1 px-2 rounded">
                                    {item.status}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    : !isLoading && (
                        <li className="d-flex align-items-center justify-content-center">
                          <span className="appointment-card_field p-5 px-2">
                            Not found any latest update
                          </span>
                        </li>
                      )}
                  {isLoading && (
                    <li>
                      <Skeleton count={6} height={50} />
                    </li>
                  )}
                </ul>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-xxl-6 col-lg-6">
          <div className="card border-0 pb-0">
            <div className="card-header border-0 pb-0">
              <h4 className="card-title">New Messages</h4>
            </div>
            <div className="card-body">
              <PerfectScrollbar
                style={{ height: "370px" }}
                id="DZ_W_Todo3"
                className="widget-media dz-scroll height370 ps ps--active-y"
              >
                <ul className="timeline">
                  {unReadMessage?.length > 0
                    ? !isMessageLoading &&
                      unReadMessage?.map((item,index) => {
                        return (
                          <li key={index}>
                            <div className="timeline-panel">
                              <div className="media me-2">
                                <img
                                  alt="avatar"
                                  width="50"
                                  src={
                                    item.receiver?.image
                                      ? process.env.REACT_APP_BUCKET_BASE_URL +
                                        item.receiver?.image
                                      : User
                                  }
                                />
                              </div>
                              <div className="media-body ml-4">
                                <h5 className="mb-1">
                                  {item?.receiver.name}{" "}
                                  <small className="text-muted">
                                    {moment(item.updatedAt).fromNow()}
                                  </small>
                                </h5>
                                <p className="mb-1">{item.message}</p>
                                <button
                                  className="btn btn-primary btn-xxs shadow"
                                  onClick={() => handleSelectConversation(item)}
                                >
                                  Reply
                                </button>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    : !isMessageLoading && (
                        <li className="d-flex align-items-center justify-content-center">
                          <span className="appointment-card_field p-5 px-2">
                            Not found any latest Message
                          </span>
                        </li>
                      )}
                  {isMessageLoading && (
                    <li>
                      <Skeleton count={6} height={50} />
                    </li>
                  )}
                </ul>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
