import React, { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom";
import {
  getAppointmentDetailsById,
  sendPrescriptionToUser,
  sendTestToUser,
} from "../../services/DashboardAsyncFunctions";
import { FaVideo } from "react-icons/fa";
import User from "../../images/DummyUser.png";
import RespondToAppointment from "../modal/RespondToAppointment";
import moment from "moment";
import { APPOINTMENTS_EVENTS } from "../../utils/content";
import eventBus from "../../services/Eventbus";
import SelectPharmacy from "../modal/SelectPharmacy";
import PharmacyCard from "../components/cards/PharmacyCard";
import { AiFillExperiment } from "react-icons/ai";
import AddSuggestions from "../modal/AddSuggestions";
import SelectLab from "../modal/SelectLab";
import { selectConversationForChat } from "../../store/actions/SessionActions";
import { useDispatch } from "react-redux";
import VideoCallFormFill from "../modal/VideoCallFormFill";
import { FaStickyNote, FaUserInjured } from "react-icons/fa";
import VideoCallFormFillAppointment from "../modal/VideoCallFormFillAppointment";
import VitalsFormFill from "../modal/VitalsFormFill";
import ImageUpload from "../modal/ImageUpload";

function AppointmentDetails() {
  const appointmentId = useParams();
  const dispatch = useDispatch();
  const loginType = localStorage.getItem("_anytime_doctor_type_");
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showPharmacyModal, setShowPharmacyModal] = useState(false);
  const [requestedPharmacies, setRequestedPharmacies] = useState(null);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [showSuggestionMessage, setShowSuggestionMessage] = useState("");
  const [showFormFillModal, setShowFormFillModal] = useState(false);
  const [showFormFillVitalsModal, setShowFormFillVitalsModal] = useState(false);
  const [uploadedFilesShowModal, setUploadedFilesShowModal] = useState(false);

  const [showLabModal, setShowLabModal] = useState(false);

  const navigate = useHistory();

  async function getAppointmentDetails() {
    if (!appointmentId?.id) {
      toast.error("Could not fetch details");
      return;
    }
    setIsLoading(true);
    try {
      let response = await getAppointmentDetailsById(appointmentId?.id);
      setAppointmentDetails(response?.data?.data);
      setShowResponseModal(false);
      setRequestedPharmacies(response?.data?.data?.pharmacies);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function submitPrescription(prescriptionList, pharmaIdList) {
    setIsLoading(true);
    let payload;
    if (pharmaIdList != null) {
      payload = {
        appointmentId: appointmentId.id,
        pharmacies: [...pharmaIdList],
        prescriptions: [...prescriptionList],
      };
    } else {
      payload = {
        appointmentId: appointmentId.id,
        pharmacies: [],
        prescriptions: [...prescriptionList],
      };
    }

    try {
      const result = await sendPrescriptionToUser(payload);
      if (result.status == 200) {
        toast.success("Prescription sent to user");
        setShowPharmacyModal(false);
        getAppointmentDetails();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function submitTest(testList, labList) {
    setIsLoading(true);
    let payload;
    if (labList != null) {
      payload = {
        appointmentId: appointmentId.id,
        labs: [...labList],
        labTest: [...testList],
      };
    } else {
      payload = {
        appointmentId: appointmentId.id,
        labs: [],
        labTest: [...testList],
      };
    }
    try {
      const result = await sendTestToUser(payload);
      if (result.status == 200) {
        toast.success("Test sent to user");
        setShowLabModal(false);
        getAppointmentDetails();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAppointmentDetails();
    let eventId = eventBus.on(
      APPOINTMENTS_EVENTS.GET_APPOINTMENT_DETAILS,
      getAppointmentDetails
    );
    return () => {
      eventBus.removeListener(eventId);
    };
  }, [showSuggestionMessage]);

  const chatHandler = () => {
    dispatch(
      selectConversationForChat({
        conversationId: appointmentDetails?.conversation,
        name: appointmentDetails?.user?.name,
        Id: appointmentDetails?.user?._id,
        image: appointmentDetails?.user?.image,
        email: appointmentDetails?.user?.email,
      })
    );
    navigate.push("/chat");
  };

  const specificTime = new Date(appointmentDetails?.timeSlots[0]);
  const fifteenMinutesBefore = new Date(specificTime.getTime() - 1 * 60 * 1000);
  const fifteenMinutesAfter = new Date(specificTime.getTime() + 16 * 60 * 1000);
  const currentTime = new Date();

  return (
    <>
      <SelectPharmacy
        handleSubmit={submitPrescription}
        appointmentId={appointmentId?.id}
        show={showPharmacyModal}
        onHide={() => setShowPharmacyModal(false)}
      />
      <SelectLab
        handleSubmit={submitTest}
        appointmentId={appointmentId?.id}
        show={showLabModal}
        onHide={() => setShowLabModal(false)}
      />
      {!isLoading && appointmentDetails && (
        <RespondToAppointment
          show={showResponseModal}
          onHide={() => setShowResponseModal(false)}
          appointmentId={appointmentId?.id}
          type={"details"}
          isReschedule={appointmentDetails?.isReschedule}
        />
      )}
      <ImageUpload
        show={uploadedFilesShowModal}
        onHide={() => setUploadedFilesShowModal(false)}
        orderId={appointmentId}
        fetchOrderDetails={() => getAppointmentDetails()}
      />
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto w-100 d-flex align-items-center justify-content-between flex-wrap">
          <h2 className="text-black font-w600 mb-0">Appointment Details</h2>
          <div className="col-auto d-flex align-items-start flex-wrap mt-4">
            {/* {loginType === "Doctor" &&
              appointmentDetails?.type === "urgentCare" &&
              appointmentDetails?.status === "OnGoing" && (
                <button
                  className="btn btn-primary p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                  onClick={() => {
                    navigate.push(`/video-call/${appointmentDetails?._id}`);
                    localStorage.setItem("video-call-show", true);
                  }}
                  disabled={
                    !(
                      currentTime >= fifteenMinutesBefore &&
                      currentTime <= fifteenMinutesAfter
                    )
                  }
                >
                  Join call
                </button>
              )} */}
            {appointmentDetails?.status === "Pending" ? (
              <>
                <button
                  className="btn btn-primary p-2 px-3 fs-12 mb-2 mb-md-0"
                  onClick={() => setShowResponseModal(true)}
                >
                  Respond
                </button>
                {appointmentDetails?.files.length > 0 && (
                  <button
                    className="btn btn-warning p-2 px-2.5 fs-12 ml-2 mb-2 mb-md-0"
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_BUCKET_BASE_URL +
                          appointmentDetails?.files[0]?.file,
                        "_blank"
                      )
                    }
                  >
                    <FaStickyNote size={16} />
                  </button>
                )}
              </>
            ) : (
              appointmentDetails?.isReschedule &&
              appointmentDetails?.rescheduleStatus === "Pending" && (
                <>
                  <button
                    className="btn btn-primary p-2 px-3 fs-12 mb-2 mb-md-0"
                    onClick={() => setShowResponseModal(true)}
                  >
                    Respond
                  </button>
                  {appointmentDetails?.files.length > 0 && (
                    <button
                      className="btn btn-warning p-2 px-2.5 fs-12 ml-2 mb-2 mb-md-0"
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_BUCKET_BASE_URL +
                            appointmentDetails?.files[0]?.file,
                          "_blank"
                        )
                      }
                    >
                      <FaStickyNote size={16} />
                    </button>
                  )}
                </>
              )
            )}
            {appointmentDetails?.status === "Rejected" && (
              <>
                <button
                  className="btn btn-danger p-2 px-3 fs-12 mb-2 mb-md-0"
                  disabled
                >
                  Rejected
                </button>
                {appointmentDetails?.files.length > 0 && (
                  <button
                    className="btn btn-warning p-2 px-2.5 fs-12 ml-2 mb-2 mb-md-0"
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_BUCKET_BASE_URL +
                          appointmentDetails?.files[0]?.file,
                        "_blank"
                      )
                    }
                  >
                    <FaStickyNote size={16} />
                  </button>
                )}
              </>
            )}
            {(appointmentDetails?.status === "Accepted" ||
              appointmentDetails?.status === "Completed" ||
              appointmentDetails?.status === "OnGoing") &&
            !appointmentDetails?.isReschedule ? (
              <>
                <button
                  className="btn btn-success p-2 px-3 fs-12 mb-2 mb-md-0"
                  disabled
                >
                  {appointmentDetails?.status === "Accepted"
                    ? "Accepted"
                    : "Completed"}
                </button>
                {loginType === "Doctor" && (
                  <button
                    className="btn btn-primary p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                    onClick={chatHandler}
                  >
                    Chat
                  </button>
                )}
                {loginType === "Doctor" &&
                  currentTime <= fifteenMinutesAfter &&
                  appointmentDetails?.status !== "Completed" && (
                    <button
                      className="btn btn-primary p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                      onClick={() => {
                        const sessionData = {
                          sessionId: appointmentDetails?.callDetail?.sessionId,
                          token: appointmentDetails?.callDetail?.token,
                        };
                        navigate.push(`/video-call/${appointmentDetails?._id}`);
                        localStorage.setItem("video-call-show", true);
                        localStorage.setItem(
                          "sessionInfo",
                          JSON.stringify(sessionData)
                        );
                        localStorage.setItem(
                          "VideoCallAppointment",
                          appointmentDetails?._id
                        );
                      }}
                      disabled={
                        !(
                          currentTime >= fifteenMinutesBefore &&
                          currentTime <= fifteenMinutesAfter
                        )
                      }
                    >
                      Join call at{" "}
                      {moment(appointmentDetails?.timeSlots[0]).format(
                        "DD, MMM yyyy"
                      )}
                      , ({moment(appointmentDetails?.timeSlots[0]).format("LT")}
                      )
                    </button>
                  )}
                {loginType === "Doctor" && (
                  <button
                    className="btn btn-success p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                    onClick={() => setShowFormFillModal(true)}
                  >
                    Form
                  </button>
                )}
                {loginType !== "Doctor" && (
                  <button
                    className="btn btn-success p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                    onClick={() => setShowFormFillVitalsModal(true)}
                  >
                    Add Vitals
                  </button>
                )}
                {loginType !== "Doctor" && (
                  <button
                    className="btn btn-success p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                    onClick={() => setShowFormFillModal(true)}
                  >
                    Medical History
                  </button>
                )}
                {loginType === "Doctor" && (
                  <button
                    className="btn btn-success p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                    onClick={() => {
                      if (appointmentDetails?.bookingFor == "mySelf") {
                        navigate.push(
                          `/patients/${appointmentDetails?.user?._id}?type=User`
                        );
                      } else {
                        navigate.push(
                          `/patients/${appointmentDetails?.memberId}?type=Member`
                        );
                      }
                    }}
                  >
                    <FaUserInjured size={16} />
                  </button>
                )}

                {appointmentDetails?.files.length > 0 && (
                  <button
                    className="btn btn-warning p-2 px-2.5 fs-12 ml-2 mb-2 mb-md-0"
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_BUCKET_BASE_URL +
                          appointmentDetails?.files[0]?.file,
                        "_blank"
                      )
                    }
                  >
                    <FaStickyNote size={16} />
                  </button>
                )}
              </>
            ) : (
              (appointmentDetails?.rescheduleStatus === "Accepted" ||
                appointmentDetails?.rescheduleStatus === "Completed") &&
              appointmentDetails?.isReschedule && (
                <>
                  <button
                    className="btn btn-success p-2 px-3 fs-12 mb-2 mb-md-0"
                    disabled
                  >
                    {appointmentDetails?.status === "Accepted"
                      ? "Accepted"
                      : "Completed"}
                  </button>
                  {loginType === "Doctor" && (
                    <button
                      className="btn btn-primary p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                      onClick={chatHandler}
                    >
                      Chat
                    </button>
                  )}
                  {loginType === "Doctor" &&
                    currentTime <= fifteenMinutesAfter &&
                    appointmentDetails?.status !== "Completed" && (
                      <button
                        className="btn btn-primary p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                        onClick={() => {
                          navigate.push(
                            `/video-call/${appointmentDetails?._id}`
                          );
                          localStorage.setItem("video-call-show", true);
                        }}
                        disabled={
                          !(
                            currentTime >= fifteenMinutesBefore &&
                            currentTime <= fifteenMinutesAfter
                          )
                        }
                      >
                        Join call at{" "}
                        {moment(appointmentDetails?.timeSlots[0]).format(
                          "DD, MMM yyyy"
                        )}
                        , (
                        {moment(appointmentDetails?.timeSlots[0]).format("LT")})
                      </button>
                    )}
                  {loginType === "Doctor" && (
                    <button
                      className="btn btn-success p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                      onClick={() => setShowFormFillModal(true)}
                    >
                      Form
                    </button>
                  )}
                  {loginType !== "Doctor" && (
                    <button
                      className="btn btn-success p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                      onClick={() => setShowFormFillVitalsModal(true)}
                    >
                      Add Vitals
                    </button>
                  )}
                  {loginType !== "Doctor" && (
                    <button
                      className="btn btn-success p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                      onClick={() => setShowFormFillVitalsModal(true)}
                    >
                      Medical History
                    </button>
                  )}
                  {loginType === "Doctor" && (
                    <button
                      className="btn btn-success p-2 px-3 fs-12 ml-2 mb-2 mb-md-0"
                      onClick={() => {
                        if (appointmentDetails?.bookingFor == "mySelf") {
                          navigate.push(
                            `/patients/${appointmentDetails?.user?._id}?type=User`
                          );
                        } else {
                          navigate.push(
                            `/patients/${appointmentDetails?.memberId}?type=Member`
                          );
                        }
                      }}
                    >
                      <FaUserInjured size={16} />
                    </button>
                  )}

                  {appointmentDetails?.files.length > 0 && (
                    <button
                      className="btn btn-warning p-2 px-2.5 fs-12 ml-2 mb-2 mb-md-0"
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_BUCKET_BASE_URL +
                            appointmentDetails?.files[0]?.file,
                          "_blank"
                        )
                      }
                    >
                      <FaStickyNote size={16} />
                    </button>
                  )}
                </>
              )
            )}
          </div>
        </div>
      </div>

      {!isLoading && appointmentDetails && (
        <div className=" mt-4">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <Card className="p-3 flex-row flex-grow-1 mb-3">
                  <div className="d-flex align-items-start">
                    {appointmentDetails?.bookingFor === "mySelf" && (
                      <img
                        src={
                          appointmentDetails?.user?.image
                            ? process.env.REACT_APP_BUCKET_BASE_URL +
                              appointmentDetails?.user?.image
                            : User
                        }
                        alt=""
                        className="appointment-details-img"
                      />
                    )}
                    {appointmentDetails?.bookingFor === "familyMember" && (
                      <img
                        src={
                          appointmentDetails?.member?.image
                            ? process.env.REACT_APP_BUCKET_BASE_URL +
                              appointmentDetails?.member?.image
                            : User
                        }
                        alt=""
                        className="appointment-details-img"
                      />
                    )}
                  </div>
                  <div className="row w-100 ml-2">
                    <div className="col-6">
                      <div className="">
                        <label htmlFor="" className="fs-12 mb-0">
                          Patient Name
                        </label>
                        <div className="fs-14 font-weight-bold capitalize">
                          {appointmentDetails?.bookingFor === "familyMember"
                            ? appointmentDetails?.member?.name
                            : appointmentDetails?.user?.name}
                        </div>
                      </div>
                      <div className="">
                        <label htmlFor="" className="fs-12 mb-0">
                          Email
                        </label>
                        <div className="fs-14 font-weight-bold capitalize">
                          {appointmentDetails?.bookingFor === "familyMember"
                            ? appointmentDetails?.member?.memberEmail
                            : appointmentDetails?.user?.email}
                        </div>
                      </div>
                      <div className="">
                        <label htmlFor="" className="fs-12 mb-0">
                          Gender
                        </label>
                        <div className="fs-14 font-weight-bold capitalize">
                          {appointmentDetails?.bookingFor === "familyMember"
                            ? appointmentDetails?.member?.gender
                            : appointmentDetails?.user?.gender}
                        </div>
                      </div>
                      <div className="">
                        <label htmlFor="" className="fs-12 mb-0">
                          Age
                        </label>
                        <div className="fs-14 font-weight-bold capitalize">
                          {appointmentDetails?.bookingFor === "familyMember"
                            ? moment().diff(
                                moment(
                                  appointmentDetails?.member?.dateOfBirth,
                                  "DD-MM-YYYY"
                                ),
                                "years"
                              )
                            : moment().diff(
                                moment(
                                  appointmentDetails?.user?.dateOfBirth,
                                  "DD-MM-YYYY"
                                ),
                                "years"
                              )}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <label htmlFor="" className="fs-12 mb-0">
                          Booking For
                        </label>
                        <div className="fs-14 font-weight-bold capitalize">
                          {appointmentDetails?.bookingFor == "mySelf"
                            ? "Self"
                            : "Member"}
                        </div>
                      </div>
                      <div className="">
                        <label htmlFor="" className="fs-12 mb-0">
                          Method
                        </label>
                        <div className="fs-14 font-weight-bold capitalize">
                          {appointmentDetails?.method}
                        </div>
                      </div>
                      <div className="">
                        <label htmlFor="" className="fs-12 mb-0">
                          Time Slot
                        </label>
                        {appointmentDetails?.isReschedule ? (
                          <div className="fs-14 font-weight-bold capitalize">
                            {moment(appointmentDetails?.rescheduleTime).format(
                              "DD, MMM yyyy"
                            )}
                            , (
                            {moment(appointmentDetails?.rescheduleTime).format(
                              "LT"
                            )}
                            )
                          </div>
                        ) : (
                          <div className="fs-14 font-weight-bold capitalize">
                            {moment(appointmentDetails?.timeSlots[0]).format(
                              "DD, MMM yyyy"
                            )}
                            , (
                            {moment(appointmentDetails?.timeSlots[0]).format(
                              "LT"
                            )}
                            )
                          </div>
                        )}
                      </div>
                      <div className="">
                        <label htmlFor="" className="fs-12 mb-0">
                          Phone Number
                        </label>
                        <div className="fs-14 font-weight-bold capitalize">
                          {appointmentDetails?.bookingFor === "familyMember"
                            ? appointmentDetails?.member?.phoneNumber
                            : appointmentDetails?.user?.phoneNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </Card>
                <div className="d-flex w-100">
                  <Card className="p-3  flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4>Medical Record</h4>
                      {loginType === "Doctor" && (
                        <button
                          className="btn btn-outline-primary p-2 px-3 fs-12"
                          disabled={
                            appointmentDetails?.status == "Pending" ||
                            appointmentDetails?.rescheduleStatus == "Pending"
                          }
                          onClick={() => setShowFormFillModal(true)}
                        >
                          Edit <i className="flaticon-381-plus"></i>{" "}
                        </button>
                      )}
                    </div>

                    {appointmentDetails?.medicalRecord && (
                      <div className="d-flex align-items-start flex-column mt-3">
                        {appointmentDetails?.medicalRecord?.vitals && (
                          <div className="pharma-card_distance mb-2">
                            <span>
                              Vitals:
                              <b className="font-weight-bold">
                                {appointmentDetails?.medicalRecord?.vitals}
                              </b>
                            </span>
                          </div>
                        )}
                        {appointmentDetails?.medicalRecord
                          ?.chiefOfComplaint && (
                          <div className="pharma-card_distance mb-2">
                            <span>
                              Chief Of Complaint:
                              <b>
                                {
                                  appointmentDetails?.medicalRecord
                                    ?.chiefOfComplaint
                                }
                              </b>
                            </span>
                          </div>
                        )}
                        {appointmentDetails?.medicalRecord?.hpi && (
                          <div className="pharma-card_distance mb-2">
                            <span>
                              Hpi:
                              <b>{appointmentDetails?.medicalRecord?.hpi}</b>
                            </span>
                          </div>
                        )}
                        {appointmentDetails?.medicalRecord?.physicalExam && (
                          <div className="pharma-card_distance mb-2">
                            <span>
                              Physical Exam:
                              <b>
                                {
                                  appointmentDetails?.medicalRecord
                                    ?.physicalExam
                                }
                              </b>
                            </span>
                          </div>
                        )}
                        {appointmentDetails?.medicalRecord?.diagnosis && (
                          <div className="pharma-card_distance mb-2">
                            <span>
                              Diagnosis:
                              <b>
                                {appointmentDetails?.medicalRecord?.diagnosis}
                              </b>
                            </span>
                          </div>
                        )}
                        {appointmentDetails?.medicalRecord?.assessment && (
                          <div className="pharma-card_distance mb-2">
                            <span>
                              Assessment:
                              <b>
                                {appointmentDetails?.medicalRecord?.assessment}
                              </b>
                            </span>
                          </div>
                        )}
                        {appointmentDetails?.medicalRecord?.investigation && (
                          <div className="pharma-card_distance mb-2">
                            <span>
                              Investigation:
                              <b>
                                {
                                  appointmentDetails?.medicalRecord
                                    ?.investigation
                                }
                              </b>
                            </span>
                          </div>
                        )}
                        {appointmentDetails?.medicalRecord?.medications && (
                          <div className="pharma-card_distance mb-2">
                            <span>
                              Medications:
                              <b>
                                {appointmentDetails?.medicalRecord?.medications}
                              </b>
                            </span>
                          </div>
                        )}
                        {appointmentDetails?.medicalRecord?.plan && (
                          <div className="pharma-card_distance mb-2">
                            <span>
                              Plan:
                              <b>{appointmentDetails?.medicalRecord?.plan}</b>
                            </span>
                          </div>
                        )}
                        {appointmentDetails?.medicalRecord?.other && (
                          <div className="pharma-card_distance mb-2">
                            <span>
                              Note:
                              <b>{appointmentDetails?.medicalRecord?.other}</b>
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {appointmentDetails?.bookingFor == "mySelf" ? (
                      <div>
                        <h4>Patient Medical History</h4>
                        {appointmentDetails?.user && (
                          <div className="d-flex align-items-start flex-column mt-3">
                            {appointmentDetails?.user?.maritalStatus && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Martial Status:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.user?.maritalStatus}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.user?.allergies.length > 0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Allergies:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.user?.allergies?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.user?.familyHistory.length >
                              0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Family History:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.user?.familyHistory?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.user?.immunization.length >
                              0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Immunization:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.user?.immunization?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.user?.lifeStyle.length > 0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Lifestyle:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.user?.lifeStyle?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.user?.medication.length >
                              0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Medication:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.user?.medication?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.user?.pastMedicalHistory
                              .length > 0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Past Medical History:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.user?.pastMedicalHistory?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.user?.surgeryHistory.length >
                              0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Surgery History:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.user?.surgeryHistory?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <h4>Medical History</h4>
                        {appointmentDetails?.member && (
                          <div className="d-flex align-items-start flex-column mt-3">
                            {appointmentDetails?.member?.maritalStatus && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Martial Status:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.member?.maritalStatus}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.member?.allergies.length >
                              0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Allergies:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.member?.allergies?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.member?.familyHistory.length >
                              0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Family History:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.member?.familyHistory?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.member?.immunization.length >
                              0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Immunization:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.member?.immunization?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.member?.lifeStyle.length >
                              0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Lifestyle:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.member?.lifeStyle?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.member?.medication.length >
                              0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Medication:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.member?.medication?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.member?.pastMedicalHistory
                              .length > 0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Past Medical History:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.member?.pastMedicalHistory?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                            {appointmentDetails?.member?.surgeryHistory.length >
                              0 && (
                              <div className="pharma-card_distance mb-2">
                                <span>
                                  Surgery History:{" "}
                                  <b className="font-weight-bold">
                                    {appointmentDetails?.member?.surgeryHistory?.join(
                                      ", "
                                    )}
                                  </b>
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="">
                {appointmentDetails?.reasonVisit && (
                  <div className="d-flex w-100">
                    <Card className="p-3  flex-grow-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>Reason Visit</h4>
                      </div>
                      <div>
                        <div className="d-flex align-items-start flex-column mt-3">
                          <div className="pharma-card_distance">
                            {appointmentDetails?.reasonVisit}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}

                <div className="d-flex w-100">
                  <Card className="p-3  flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4>Upload File</h4>
                      {loginType === "Doctor" && (
                        <button
                          className="btn btn-outline-primary p-2 px-3 fs-12"
                          disabled={
                            appointmentDetails?.status == "Pending" ||
                            appointmentDetails?.rescheduleStatus == "Pending"
                          }
                          onClick={() => setUploadedFilesShowModal(true)}
                        >
                          Upload <i className="flaticon-381-plus"></i>{" "}
                        </button>
                      )}
                    </div>
                    <ul
                      className="list-group overflow-auto mt-2"
                      style={{ maxHeight: "200px", width: "100%" }}
                    >
                      {appointmentDetails?.doctorFiles.map((fileUrl, index) => {
                        return (
                          <li
                            key={index}
                            className="list-group-item d-flex justify-content-between align-items-center"
                            style={{ wordWrap: "break-word" }}
                          >
                            <a
                              href={fileUrl.file}
                              target="_blank"
                              // rel="noopener noreferrer"
                              className="text-truncate"
                              style={{ maxWidth: "70%" }}
                            >
                              Upload File {index + 1}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </Card>
                </div>

                {appointmentDetails?.reports.length > 0 && (
                  <div className="d-flex w-100 ">
                    <Card className="p-3 flex-grow-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>Lab Reports</h4>
                      </div>
                      <ul
                        className="list-group overflow-auto mt-2"
                        style={{ maxHeight: "200px", width: "100%" }}
                      >
                        {appointmentDetails?.reports.map((fileUrl, index) => {
                          const updatedUrl = fileUrl.startsWith("http")
                            ? fileUrl
                            : process.env.REACT_APP_BUCKET_BASE_URL + fileUrl;

                          return (
                            <li
                              key={index}
                              className="list-group-item d-flex justify-content-between align-items-center"
                              style={{ wordWrap: "break-word" }}
                            >
                              <a
                                href={updatedUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-truncate"
                                style={{ maxWidth: "70%" }}
                              >
                                Report {index + 1}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </Card>
                  </div>
                )}

                <div className="d-flex w-100">
                  <Card className="p-3  flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4>Prescription</h4>
                      {appointmentDetails?.prescriptions?.length == 0 &&
                        loginType === "Doctor" && (
                          <button
                            className="btn btn-outline-primary p-2 px-3 fs-12"
                            disabled={
                              appointmentDetails?.status == "Pending" ||
                              appointmentDetails?.rescheduleStatus == "Pending"
                            }
                            onClick={() => setShowPharmacyModal(true)}
                          >
                            Add Prescription{" "}
                            <i className="flaticon-381-plus"></i>{" "}
                          </button>
                        )}
                    </div>
                    <div>
                      {appointmentDetails?.prescriptions &&
                        appointmentDetails?.prescriptions.map((item, index) => (
                          <div
                            key={item._id}
                            className="d-flex align-items-start flex-column mt-3"
                          >
                            <div className="pharma-card_distance mb-2">
                              <span className="font-weight-bold">
                                {index + 1}.
                              </span>{" "}
                              {item.name}
                            </div>
                            <div
                              className="pharma-card_distance"
                              style={{ marginLeft: "20px" }}
                            >
                              <span className="font-weight-bold">
                                Description:
                              </span>{" "}
                              {item.description}
                            </div>
                          </div>
                        ))}
                    </div>
                  </Card>
                </div>

                <div className="d-flex w-100">
                  <Card className="p-3  flex-grow-1">
                    <h4>Pharmacy</h4>
                    {appointmentDetails &&
                      appointmentDetails.pharmacies.map((item, index) => (
                        <PharmacyCard
                          key={item._id}
                          handleSelection={() => {}}
                          pharmaId={item._id}
                          selectedPharmacies={[]}
                          name={item.pharmacyName}
                          email={item.email}
                          location={item.address}
                          index={index}
                          type="display"
                        />
                      ))}
                  </Card>
                </div>

                <div className="d-flex w-100">
                  <Card className="p-3  flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4>Lab Test</h4>
                      {appointmentDetails?.labTest?.length == 0 &&
                        loginType === "Doctor" && (
                          <button
                            className="btn btn-outline-primary p-2 px-3 fs-12"
                            disabled={
                              appointmentDetails?.status == "Pending" ||
                              appointmentDetails?.rescheduleStatus == "Pending"
                            }
                            onClick={() => setShowLabModal(true)}
                          >
                            Add Test <i className="flaticon-381-plus"></i>{" "}
                          </button>
                        )}
                    </div>
                    <div>
                      {appointmentDetails?.labTest &&
                        appointmentDetails?.labTest.map((item, index) => (
                          <div
                            key={item._id}
                            className="d-flex align-items-start flex-column mt-3"
                          >
                            <div className="pharma-card_distance mb-2">
                              <span className="font-weight-bold">
                                {index + 1}.
                              </span>{" "}
                              {item.name}
                            </div>
                          </div>
                        ))}
                    </div>
                  </Card>
                </div>

                <div className="d-flex w-100">
                  <Card className="p-3  flex-grow-1">
                    <h4>Labs</h4>
                    {appointmentDetails &&
                      appointmentDetails.labs.map((item, index) => (
                        <PharmacyCard
                          key={item._id}
                          handleSelection={() => {}}
                          pharmaId={item._id}
                          selectedPharmacies={[]}
                          name={item.labName}
                          email={item.email}
                          location={item.address}
                          index={index}
                          type="display"
                        />
                      ))}
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="w-100 h-50 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}

      {showSuggestionModal && (
        <AddSuggestions
          show={showSuggestionModal}
          onHide={() => setShowSuggestionModal(false)}
          appointmentId={appointmentId?.id}
          setShowSuggestionMessage={setShowSuggestionMessage}
        />
      )}
      {showFormFillModal && (
        <VideoCallFormFillAppointment
          show={showFormFillModal}
          onHide={() => setShowFormFillModal(false)}
          appointmentId={appointmentId?.id}
          medicalRecord={appointmentDetails?.medicalRecord}
          getAppointmentDetails={getAppointmentDetails}
          data={
            appointmentDetails?.bookingFor == "mySelf"
              ? appointmentDetails?.user
              : appointmentDetails?.member
          }
          doctorType={loginType}
        />
      )}
      {showFormFillVitalsModal && (
        <VitalsFormFill
          show={showFormFillVitalsModal}
          onHide={() => setShowFormFillVitalsModal(false)}
          appointmentId={appointmentId?.id}
          getAppointmentDetails={getAppointmentDetails}
        />
      )}
    </>
  );
}

export default AppointmentDetails;
