import { z } from "zod";

export const zodValidationSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: "Email is required" })
    .email({ message: "Must be a valid email" }),
  password: z
    .string()
    .trim()
    .min(6, { message: "Password must be at least 6 characters" }),
  phoneNumber: z.coerce
    .string()
    .min(1, { message: "Phone number is required" })
    .refine((val) => !isNaN(val) && val.length >= 6, {
      message: "Phone number must be at least 6 digits long",
    }),
  name: z.string().trim().min(1, { message: "Name is required" }),
  qualification: z
    .string()
    .trim()
    .min(2, { message: "Qualification is required" }),
  experience: z.coerce
    .string()
    .min(1, { message: "Experience is required" })
    .refine((val) => !isNaN(val) && Number(val) >= 1, {
      message: "Experience must be at least 1 year",
    }),
  patientTreated: z.coerce
    .string()
    .min(1, { message: "Patient treated is required" })
    .refine((val) => !isNaN(val) && Number(val) >= 1, {
      message: "Patient treated is required",
    }),
  state: z.string().trim().min(2, { message: "State is required" }),
  city: z.string().trim().min(2, { message: "City is required" }),
  areaOfExpertise: z
    .string()
    .trim()
    .min(3, { message: "Area of Expertise is required" }),
  description: z
    .string()
    .trim()
    .min(10, { message: "Description is required" }),
  hospitalName: z
    .string()
    .trim()
    .min(10, { message: "Hospital Name is required" }),
});

export const zodValidationSchemaEditProfile = z.object({
  phoneNumber: z
    .number()
    .min(6, { message: "phone number must be at least 6 digits long" }),
  name: z.string().trim().min(1, { message: "Name is required" }),
  qualification: z
    .string()
    .trim()
    .min(2, { message: "Qualification is required" }),
  experience: z.number().min(1, { message: "Experience is required" }),
  patientTreated: z.coerce
    .string()
    .min(1, { message: "Patient treated is required" })
    .refine((val) => !isNaN(val) && Number(val) >= 1, {
      message: "Patient treated is required",
    }),
  areaOfExpertise: z
    .string()
    .trim()
    .min(3, { message: "Area of Expertise is required" }),
  state: z.string().trim().min(2, { message: "State is required" }),
  city: z.string().trim().min(2, { message: "City is required" }),
  description: z
    .string()
    .trim()
    .min(10, { message: "Description is required" }),
  hospitalName: z
    .string()
    .trim()
    .min(10, { message: "Hospital Name is required" }),
  // gender: z.string().min(1, { message: "Gender is required" }).union([z.literal('Male'), z.literal('Female')]),
});
