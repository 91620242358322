import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import {
  getUserToken,
  registerUser,
  saveTokenInLocalStorage,
  slotBook,
} from "../../services/AuthService";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { optional, z } from "zod";
import toast from "react-hot-toast";
import { zodValidationSchema } from "../../utils/zodschema";
import { specialityCategories } from "../../utils/content";
import { loginConfirmedAction } from "../../store/actions/AuthActions";
import CustomSelect from "../components/CustomSelect";
import profileImage from "../../images/dummy-profile.png";
import DummyUser from "../../images/upload.png";
import LoadingImage from "../../images/loading-image.webp";
import addFileToS3Bucket from "../../services/Aws";

function Registration(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [code, setCode] = useState("+1");
  const [googleAddress, setGoogleAddress] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [gender, setGender] = useState("Male");
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [resumeImageUrl, setResumeImageUrl] = useState(null);
  const [govImageUrl, setGovImageUrl] = useState(null);
  const [degreeImageUrl, setDegreeImageUrl] = useState(null);
  const imageRef = useRef();
  const imageRefResume = useRef();
  const imageRefGov = useRef();
  const imageRefDegree = useRef();

  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [imageResumeUploadLoading, setImageResumeUploadLoading] =
    useState(false);
  const [imageDegreeUploadLoading, setImageDegreeUploadLoading] =
    useState(false);
  const [imageGovUploadLoading, setImageGovUploadLoading] = useState(false);

  const [locationDetails, setLocationDetails] = useState({
    lat: null,
    lng: null,
    label: "",
  });
  const [varifyEmailMessage, setVarifyEmailMessage] = useState(null);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(zodValidationSchema),
  });
  const dispatch = useDispatch();

  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption, "selected options");
    setSelectedOption(selectedOption);
  };

  const handleAddress = async (place) => {
    try {
      setGoogleAddress(place.label);
      const results = await geocodeByAddress(place.label);
      console.log(results, "results");

      const latLng = await getLatLng(results[0]);

      const newLocationDetails = {
        lat: latLng.lat,
        lng: latLng.lng,
        label: place.label,
      };

      setLocationDetails(newLocationDetails);
    } catch (error) {
      console.error("Error getting address details:", error);
    }
  };

  async function handleImageUpload(files) {
    if (!files?.[0]) return;
    console.log(files);
    setImageUploadLoading(true);
    try {
      let response = await addFileToS3Bucket(files[0], "appointment");
      setProfileImageUrl(response.Location);
    } catch (error) {
      toast.error("Error occured while uploading media!");
      setProfileImageUrl(null);
    } finally {
      setImageUploadLoading(false);
    }
  }

  async function handleResumeImageUpload(files) {
    if (!files?.[0]) return;
    console.log(files);
    setImageResumeUploadLoading(true);
    try {
      let response = await addFileToS3Bucket(files[0], "resume");
      setResumeImageUrl(response.Location);
    } catch (error) {
      toast.error("Error occured while uploading media!");
      setResumeImageUrl(null);
    } finally {
      setImageResumeUploadLoading(false);
    }
  }

  async function handleGovImageUpload(files) {
    if (!files?.[0]) return;
    console.log(files);
    setImageGovUploadLoading(true);
    try {
      let response = await addFileToS3Bucket(files[0], "gov");
      setGovImageUrl(response.Location);
    } catch (error) {
      toast.error("Error occured while uploading media!");
      setGovImageUrl(null);
    } finally {
      setImageGovUploadLoading(false);
    }
  }

  async function handleDegreeImageUpload(files) {
    if (!files?.[0]) return;
    console.log(files);
    setImageDegreeUploadLoading(true);
    try {
      let response = await addFileToS3Bucket(files[0], "degree");
      setDegreeImageUrl(response.Location);
    } catch (error) {
      toast.error("Error occured while uploading media!");
      setDegreeImageUrl(null);
    } finally {
      setImageDegreeUploadLoading(false);
    }
  }

  const onSubmit = async (data) => {
    if (!selectedOption) {
      toast.error("Speciality is required!");
      return;
    } else if (!googleAddress) {
      toast.error("Address is required!");
    } else if (profileImageUrl == null || profileImageUrl == "") {
      toast.error("Profile image is required!");
    } else if (
      resumeImageUrl == null ||
      resumeImageUrl == "" ||
      govImageUrl == null ||
      govImageUrl == "" ||
      degreeImageUrl == null ||
      degreeImageUrl == ""
    ) {
      toast.error("All documents are required!");
    } else {
      setErrorAddress("");
      const {
        email,
        phoneNumber,
        password,
        experience,
        qualification,
        patientTreated,
        areaOfExpertise,
        description,
        state,
        city,
        hospitalName,
        name,
      } = data;

      const specialty = selectedOption.map((item) => item.value);

      const formData = {
        name,
        email: email,
        phoneNumber: phoneNumber,
        countryCode: code,
        password: password,
        type: "Doctor",
        experience: experience,
        qualification: qualification,
        patientTreated: patientTreated,
        areaOfExpertise: areaOfExpertise,
        description: description,
        specialty: specialty,
        lat: locationDetails.lat ?? 0,
        long: locationDetails.lng ?? 0,
        address: locationDetails.label ? locationDetails.label : "",
        hospital: hospitalName,
        gender,
        state:state,
        city:city,
        image: profileImageUrl,
        governmentId: govImageUrl,
        resume: resumeImageUrl,
        degree: degreeImageUrl,
      };

      setIsLoading(true);

      try {
        let response = await registerUser(formData);
        if (response.status === 200) {
          toast.success(
            "Signup successful! Please verify your account through the registered email!",
            { duration: 5000 }
          );
          setVarifyEmailMessage(
            "A verification email has been sent to your email address!"
          );
          setTimeout(() => props.history.push("/"), 3000);
          reset();
        }
      } catch (error) {
        if (error.response.data.data === "image must be a string") {
          toast.error("Image is required!");
        } else {
          toast.error(error.response?.data?.message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-8">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <h2 className="text-center mb-4">Create Account</h2>
                      {varifyEmailMessage && (
                        <div
                          role="alert"
                          className="fade alert-dismissible text-center fade show alert alert-success show"
                        >
                          {varifyEmailMessage}
                        </div>
                      )}

                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="profile-img-container relative">
                          {!imageUploadLoading && (
                            <img
                              src={
                                profileImageUrl ? profileImageUrl : profileImage
                              }
                              width={100}
                              height={100}
                              className="signup-img"
                            />
                          )}
                          {imageUploadLoading && (
                            <img
                              src={LoadingImage}
                              width={100}
                              height={100}
                              className="signup-img"
                            />
                          )}

                          <input
                            ref={imageRef}
                            type="file"
                            accept="image/*"
                            className="d-none"
                            onChange={(e) => handleImageUpload(e.target.files)}
                          />
                          <i
                            className="flaticon-381-edit absolute edit-img-icon"
                            onClick={() => imageRef.current.click()}
                          ></i>
                        </div>
                        <div className="form-group">
                          <label className="mb-1 mt-3 ">
                            <strong>Full Name</strong>
                          </label>
                          <input
                            id="name"
                            type="text"
                            className="form-control global-input-field"
                            {...register("name")}
                          />
                        </div>
                        {errors.name && (
                          <div className="text-danger fs-12">
                            {errors.name?.message}
                          </div>
                        )}

                        <div className="form-group">
                          <label className="mb-1 mt-3 ">
                            <strong>Gender</strong>
                          </label>
                          <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="form-control global-input-field"
                          >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                        {errors.gender && (
                          <div className="text-danger fs-12">
                            {errors.gender?.message}
                          </div>
                        )}

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Email</strong>
                          </label>
                          <input
                            id="email"
                            type="email"
                            className="form-control global-input-field"
                            {...register("email")}
                          />
                          {errors.email && (
                            <div className="text-danger fs-12">
                              {errors.email?.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Password</strong>
                          </label>
                          <input
                            id="password"
                            type="password"
                            className="form-control global-input-field"
                            {...register("password")}
                          />
                          {errors.password && (
                            <div className="text-danger fs-12">
                              {errors.password?.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Experience </strong>{" "}
                            <span className="fs-12">(In Years)</span>
                          </label>
                          <input
                            id="experience"
                            type="text"
                            className="form-control global-input-field"
                            {...register("experience")}
                          />
                          {errors.experience && (
                            <div className="text-danger fs-12">
                              {errors.experience?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Patient Treated </strong>
                          </label>
                          <input
                            id="patientTreated"
                            type="text"
                            className="form-control global-input-field"
                            {...register("patientTreated")}
                          />
                          {errors.patientTreated && (
                            <div className="text-danger fs-12">
                              {errors.patientTreated?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Qualification</strong>
                          </label>
                          <input
                            id="qualification"
                            type="text"
                            className="form-control global-input-field"
                            {...register("qualification")}
                          />
                          {errors.qualification && (
                            <div className="text-danger fs-12">
                              {errors.qualification?.message}
                            </div>
                          )}
                        </div>

                        {/* <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Patients Treated</strong>
                          </label>
                          <input
                            id="patientTreated"
                            type="number"
                            className="form-control global-input-field"
                            {...register("patientTreated", {
                              valueAsNumber: true,
                            })}
                          />
                          {errors.patientTreated && (
                            <div className="text-danger fs-12">
                              {errors.patientTreated?.message}
                            </div>
                          )}
                        </div> */}

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Area of Expertise</strong>
                          </label>
                          <input
                            id="areaOfExpertise"
                            type="text"
                            className="form-control global-input-field"
                            {...register("areaOfExpertise")}
                          />
                          {errors.areaOfExpertise && (
                            <div className="text-danger fs-12">
                              {errors.areaOfExpertise?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Hospital Name</strong>
                          </label>
                          <input
                            id="hospitalName"
                            type="text"
                            className="form-control global-input-field"
                            {...register("hospitalName")}
                          />
                          {errors.hospitalName && (
                            <div className="text-danger fs-12">
                              {errors.hospitalName?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Description</strong>
                          </label>
                          <input
                            id="description"
                            type="text"
                            className="form-control global-input-field"
                            {...register("description")}
                          />
                          {errors.description && (
                            <div className="text-danger fs-12">
                              {errors.description?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-2 ">
                            <strong>Speciality </strong>
                          </label>
                          <CustomSelect
                            selectedOption={selectedOption}
                            handleSelectChange={handleSelectChange}
                            listItems={specialityCategories}
                            isMulti={true}
                          />
                        </div>

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>State</strong>
                          </label>
                          <input
                            id="state"
                            type="text"
                            className="form-control global-input-field"
                            {...register("state")}
                          />
                          {errors.state && (
                            <div className="text-danger fs-12">
                              {errors.state?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>City</strong>
                          </label>
                          <input
                            id="city"
                            type="text"
                            className="form-control global-input-field"
                            {...register("city")}
                          />
                          {errors.city && (
                            <div className="text-danger fs-12">
                              {errors.city?.message}
                            </div>
                          )}
                        </div>

                        <div className=" form-group">
                          <label className="mb-1 mt-3 ">
                            <strong>Address</strong>
                          </label>
                          <GooglePlacesAutocomplete
                            className=""
                            apiKey={"AIzaSyA_kz_OxgMPwWS7AEWRVUHSLSvqYFlgZAc"}
                            minLengthAutocomplete={3}
                            selectProps={{
                              value: {
                                label: googleAddress,
                                value: googleAddress,
                              },
                              onChange: (place) => {
                                handleAddress(place);
                              },
                            }}
                            onLoadFailed={() => toast.error("Error")}
                          />
                          {errorAddress && (
                            <div className="text-danger fs-12">
                              {errorAddress}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-1 mt-3 ">
                            <strong>Phone number</strong>
                          </label>
                          <div className="d-flex">
                            <div className="relative">
                              <PhoneInput
                                country={"us"}
                                enableSearch={true}
                                value={code}
                                onChange={(phone) => setCode(phone)}
                              />
                            </div>

                            <div className="w-100">
                              <input
                                id="phoneNumber"
                                type="text"
                                className="form-control global-input-field  sign-phone"
                                {...register("phoneNumber")}
                              />
                            </div>
                          </div>
                          {errors.phoneNumber && (
                            <div className="text-danger fs-12">
                              {errors.phoneNumber?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group doument-upload">
                          <div>
                            <label htmlFor="email">Resume</label>
                            <div className="relative">
                              {!imageResumeUploadLoading && (
                                <img
                                  src={
                                    resumeImageUrl ? resumeImageUrl : DummyUser
                                  }
                                  width={100}
                                  height={100}
                                  className="signup-img"
                                />
                              )}
                              {imageResumeUploadLoading && (
                                <img
                                  src={LoadingImage}
                                  width={100}
                                  height={100}
                                  className="signup-img"
                                />
                              )}

                              <input
                                ref={imageRefResume}
                                type="file"
                                accept="image/*"
                                className="d-none"
                                onChange={(e) =>
                                  handleResumeImageUpload(e.target.files)
                                }
                              />
                              <i
                                className="flaticon-381-edit absolute edit-img-icon"
                                onClick={() => imageRefResume.current.click()}
                              ></i>
                            </div>
                          </div>

                          <div>
                            <label htmlFor="email">Government Id</label>
                            <div className="relative">
                              {!imageGovUploadLoading && (
                                <img
                                  src={govImageUrl ? govImageUrl : DummyUser}
                                  width={100}
                                  height={100}
                                  className="signup-img"
                                />
                              )}
                              {imageGovUploadLoading && (
                                <img
                                  src={LoadingImage}
                                  width={100}
                                  height={100}
                                  className="signup-img"
                                />
                              )}

                              <input
                                ref={imageRefGov}
                                type="file"
                                accept="image/*"
                                className="d-none"
                                onChange={(e) =>
                                  handleGovImageUpload(e.target.files)
                                }
                              />
                              <i
                                className="flaticon-381-edit absolute edit-img-icon"
                                onClick={() => imageRefGov.current.click()}
                              ></i>
                            </div>
                          </div>

                          <div>
                            <label htmlFor="email">Degree</label>
                            <div className="relative">
                              {!imageDegreeUploadLoading && (
                                <img
                                  src={
                                    degreeImageUrl ? degreeImageUrl : DummyUser
                                  }
                                  width={100}
                                  height={100}
                                  className="signup-img"
                                />
                              )}
                              {imageDegreeUploadLoading && (
                                <img
                                  src={LoadingImage}
                                  width={100}
                                  height={100}
                                  className="signup-img"
                                />
                              )}

                              <input
                                ref={imageRefDegree}
                                type="file"
                                accept="image/*"
                                className="d-none"
                                onChange={(e) =>
                                  handleDegreeImageUpload(e.target.files)
                                }
                              />
                              <i
                                className="flaticon-381-edit absolute edit-img-icon"
                                onClick={() => imageRefDegree.current.click()}
                              ></i>
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            disabled={isLoading}
                            className="btn btn-primary btn-block"
                          >
                            {isLoading ? "Processing..." : "Submit"}
                          </button>
                        </div>
                      </form>
                      <div className="new-account mt-3 ">
                        <p>
                          Already have an account?{" "}
                          <Link className="text-primary" to="/login">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Registration;
