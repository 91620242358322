/// auth service

export const LOGIN = "serviceProvider/auth/login";

export const CHANGE_PASSWORD = "serviceProvider/auth/changePassword";

export const LOGOUT = "serviceProvider/auth/logoutDoctor";

export const DASHBOARD = "admin/auth/dashboard";

export const REGISTER_USER = "user/auth/register";

export const EDIT_USER = "serviceProvider/auth/editProfile";

export const APPOINTMENT_LISTING = "serviceProvider/auth/getRequests";

export const RESPOND_TO_REQUEST = "serviceProvider/auth/requestAction";

export const RESPOND_TO_CANCEL_REQUEST = "doctor/cancelAppointment";

export const API_CALL_URGENT_REQUEST= "doctor/requests";

export const RESPOND_END_CALL_REQUEST = "app/endService";

export const GET_APPOINTMENT_DETAILS = "serviceProvider/auth/request";

export const GET_PHARMACIES = "serviceProvider/auth/getPharmacies";

export const SEND_PRESCRIPTION = "serviceProvider/auth/sendPrescription";

export const ADD_UPLOAD_REPORT = "serviceProvider/auth/uploadfile";

export const SEND_SUGGESTION = "serviceProvider/auth/sendSuggestion";

export const GET_CHAT_MESSAGES = "serviceProvider/auth/getChat";

export const GET_CONVERSATION_LIST = "serviceProvider/auth/getConversations";

export const ADD_AVAILABLE_POST = "serviceProvider/auth/addAvailability";

export const GET_DASHBOARD_DATA = "serviceProvider/auth/dashboard";

export const GET_PROFILE_DATA = "serviceProvider/auth/getDoctorProfile";

export const GET_PATIENTS = "doctor/patientList";

export const GET_ASSISTANT_DOCTOR_LIST = "doctor/assistantDoctors";

export const GET_PATIENT_APPOINTMENTS = "doctor/userAppointments/";

export const GET_SUGGESTIONS = "doctor/medicineSuggestions";

export const GET_LAB = "serviceProvider/auth/getLab";

export const GET_LAB_SUGGENTION = "doctor/LabTestSuggestions";

export const SEND_TEST = "doctor/addLabTest";

export const ADD_ASSISTANT_DOCTOR = "doctor/assistantDoctor";

export const ADD_FEE_STRUCTURE = "serviceProvider/auth/addFee";

export const ADD_MEDICAL_RECORD = "doctor/addMedicalRecord"

export const GET_MEDICAL_RECORD = "doctor/medicalForm"

export const ADD_ADDITIONAL_MEDICAL_RECORD = "doctor/addMedicalDetails"

export const ADD_MEDICAL_RECORD_VITALS = "doctor/assistanceAddMedicalRecord"

export const ADD_AVAILABLE_DOCTOR_TYPE = "doctor/changeAvailabilityStatus"

export const RE_ATTACH_DOUC_API = "doctor/reSubmittedRequest"

export const GET_TOGGLE_STATUS = "doctor/liveRequestStatus"

export const GET_NOTIFICATION_API = "user/auth/fcm"