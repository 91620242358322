import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { Dropdown, Card, Table, Badge, Tab, Nav } from "react-bootstrap";

function AdminRequest({ show, onHide, requestType, reason, setReAttactShow }) {
  return (
    <>
      <Modal
        className="modal fade"
        backdrop="static"
        centered
        onHide={onHide}
        show={show}
      >
        <div className="animate__animated animate__fadeIn p-3" role="document">
          <Modal.Header>
            <h5 className="">
              Your Application Status{" "}
              {requestType == "Pending" ? "Pending" : "Rejected"}
            </h5>
          </Modal.Header>
          <Modal.Body>
            {requestType === "Pending" && <h5>Review in progress</h5>}
            {requestType === "Rejected" && (
              <h5>Reason for rejection: {reason}</h5>
            )}
            {requestType === "Pending" ? (
              <h4 className="text-center animate__animated animate__zoomIn animate__infinite text-danger fs-6">
                <Badge variant="warning light">Pending</Badge>
              </h4>
            ) : (
              <h4 className="text-center animate__animated animate__zoomIn animate__infinite text-danger fs-6">
                <Badge variant="danger light">Rejected</Badge>
              </h4>
            )}
            <p>
              {requestType === "Pending"
                ? "Your verification is currently in progress. We appreciate your patience during this process!"
                : "Action Required: Please reattach the required documents. Thank you!"}
            </p>
          </Modal.Body>
          {requestType === "Rejected" && (
            <Modal.Footer className=" d-flex align-items-center">
              <button
                type="button"
                className="btn btn-secondary flex-grow-1"
                onClick={() => {
                  setReAttactShow();
                  onHide();
                }}
              >
                Re attach Documents
              </button>
            </Modal.Footer>
          )}
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(AdminRequest);
