import React, { useState } from "react";
import { Card, Col, Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { HiOutlineTrash } from "react-icons/hi";
import addFileToS3Bucket from "../../services/Aws";
import { uploadReportData } from "../../services/DashboardAsyncFunctions";

function ImageUpload({ onHide, show, orderId,fetchOrderDetails }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  // Handle file change
  const handleFileChange = async (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) return;

    setLoading(true);

    try {
      const uploadedResponses = [];
      for (const file of files) {
        const response = await addFileToS3Bucket(file, "appointment");
        uploadedResponses.push(response.Location);
      }

      setUploadedFiles((prev) => [...prev, ...uploadedResponses]);
      toast.success(`${files.length} file(s) added successfully!`);
    } catch (error) {
      console.error("Error occurred while uploading media:", error);
      toast.error("Error occurred while uploading media!");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const files = uploadedFiles.map((item)=>{
      return {file: item,fileType:'image'}
    })
    try {
      const response = await uploadReportData({
        appointmentId: orderId.id,
        files: files,
      });
      onHide();
      fetchOrderDetails()
      toast.success(`Uploaded successfully!`);
    } catch (error) {
      setLoading(false);
      console.error("Error occurred while uploading media:");
    } finally {
      setLoading(false);
      console.error("Error occurred while uploading media:");
    }
  };

  return (
    <Modal
      className="modal fade"
      size="lg"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Upload files</h3>
            </div>
          </Card.Header>

          <Card.Body>
            <div className="mb-3">
              <input
                className="form-control"
                type="file"
                id="formFileMultiple"
                multiple
                onChange={handleFileChange}
              />
            </div>

            {uploadedFiles.length > 0 && (
              <div className="uploaded-files-list">
                <h5>Uploaded Files:</h5>
                <ul
                  className="list-group overflow-auto"
                  style={{ maxHeight: "200px", width: "100%" }}
                >
                  {uploadedFiles.map((fileUrl, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{ wordWrap: "break-word" }}
                    >
                      <a
                        href={fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-truncate"
                        style={{ maxWidth: "70%" }}
                      >
                        {fileUrl}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Card.Body>

          <Card.Footer>
            <button
              className="btn btn-primary w-100"
              disabled={loading || uploadedFiles.length === 0}
              onClick={handleSubmit}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Add"}
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default ImageUpload;
