import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import PatientCard from "../components/cards/PatientCard";
import {
  addAvailablityOnlineOffline,
  availableAddPost,
  getPatients,
  getProfileFetch,
} from "../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { on } from "@opentok/client";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const array = [
  {
    day: "Monday",
    startTime: "09:00 AM",
    endTime: "18:00 PM",
    status: false,
  },
  {
    day: "Tuesday",
    startTime: "09:00 AM",
    endTime: "18:00 PM",
    status: false,
  },
  {
    day: "Wednesday",
    startTime: "09:00 AM",
    endTime: "18:00 PM",
    status: false,
  },
  {
    day: "Thursday",
    startTime: "09:00 AM",
    endTime: "18:00 PM",
    status: false,
  },
  {
    day: "Friday",
    startTime: "09:00 AM",
    endTime: "18:00 PM",
    status: false,
  },
  {
    day: "Saturday",
    startTime: "09:00 AM",
    endTime: "18:00 PM",
    status: false,
  },
  {
    day: "Sunday",
    startTime: "09:00 AM",
    endTime: "18:00 PM",
    status: false,
  },
];

function Availability() {
  const [profileData, setProfileData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [slots, setSlots] = useState(array);
  const [onlineAvailable, setOnlineAvailable] = useState(false);
  const [offlineAvailable, setOfflineAvailable] = useState(false);

  const removeAMPM = (time) => {
    return time.replace(/\s?(AM|PM)$/i, "");
  };

  const toggleTheme = (day) => {
    const updatedSlots = slots?.map((item) => {
      if (item.day === day) {
        return { ...item, status: !item.status };
      }
      return item;
    });

    const formattedSlots = updatedSlots.map((item) => ({
      ...item,
      startTime: removeAMPM(item.startTime),
      endTime: removeAMPM(item.endTime),
    }));

    setSlots(updatedSlots);
    availableAddPost({ weeklySchedule: formattedSlots });
    toast.success("Updated successfully");
  };

  async function getProfileData() {
    setLoadingData(true);
    try {
      const response = await getProfileFetch();
      setProfileData(response.data?.data);
      setLoadingData(false);
    } catch (error) {
      console.log(error, "error");
      setLoadingData(false);
    }
  }

  useEffect(() => {
    if (profileData && profileData?.weeklySchedule?.length > 0) {
      setSlots(profileData?.weeklySchedule);
      console.log(profileData?.weeklySchedule);
      setOnlineAvailable(profileData?.isOnlineRequest);
      setOfflineAvailable(profileData?.isOfflineRequest);
    } else {
      setSlots(array);
    }
  }, [profileData]);

  useEffect(() => {
    getProfileData();
  }, []);

  const handleOnlineChange = () => {
    const newOnlineState = !onlineAvailable;
    setOnlineAvailable(newOnlineState);
    addAvailablityOnlineOffline({
      isOnlineRequest: newOnlineState,
      isOfflineRequest: offlineAvailable,
    });
    toast.success("Updated successfully");
  };

  const handleOfflineChange = () => {
    const newOfflineState = !offlineAvailable;
    setOfflineAvailable(newOfflineState);
    addAvailablityOnlineOffline({
      isOnlineRequest: onlineAvailable,
      isOfflineRequest: newOfflineState,
    });
    toast.success("Updated successfully");
  };

  const handleTimeChange = (day, type, value) => {
    if (!value) return;
    
    const updatedSlots = slots.map((item) => {
      if (item.day === day) {
        let timeString = value;
        if (value instanceof Date) {
          const hours = value.getHours();
          const minutes = value.getMinutes();
          const ampm = hours >= 12 ? 'PM' : 'AM';
          const hours12 = hours % 12 || 12;
          timeString = `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        }
        return { ...item, [type]: timeString };
      }
      return item;
    });
    
    setSlots(updatedSlots);
    try {
      availableAddPost({ weeklySchedule: updatedSlots });
      toast.success("Time updated successfully");
    } catch (error) {
      toast.error("Failed to update time");
      console.error("Error updating time:", error);
    }
  };

  return (
    <div>
      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="text-black font-w600 mb-0">Available</h2>
        </div>
      </div>
      <Col lg={12}>
        <Card>
          <Card.Body>
            <Table responsive striped bordered className="verticle-middle">
              <thead>
                <tr>
                  <th scope="col">Day</th>
                  <th scope="col">Start Time</th>
                  <th scope="col">End Time</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {slots?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.day}</td>
                      <td>
                        <TimePicker
                          onChange={(value) => handleTimeChange(item.day, 'startTime', value)}
                          value={item.startTime}
                          format="hh:mm a"
                          clearIcon={null}
                          clockIcon={null}
                          disableClock={true}
                          disabled={!item.status}
                          required={true}
                          openWidgetOnFocus={true}
                          hourPlaceholder="hh"
                          minutePlaceholder="mm"
                          locale="en-US"
                          amPmAriaLabel="Select AM/PM"
                        />
                      </td>
                      <td>
                        <TimePicker
                          onChange={(value) => handleTimeChange(item.day, 'endTime', value)}
                          value={item.endTime}
                          format="hh:mm a"
                          clearIcon={null}
                          clockIcon={null}
                          disableClock={true}
                          disabled={!item.status}
                          required={true}
                          openWidgetOnFocus={true}
                          hourPlaceholder="hh"
                          minutePlaceholder="mm"
                          locale="en-US"
                          amPmAriaLabel="Select AM/PM"
                        />
                      </td>
                      <td>{item.status ? "Available" : "Unavailable"}</td>
                      <td>
                        {loadingData ? (
                          <p>Loading...</p>
                        ) : (
                          <input
                            type="checkbox"
                            onChange={() => toggleTheme(item.day)}
                            checked={item.status}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={12}>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Availability Status</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form>
                <div>
                  {/* Online Checkbox */}
                  <div className="form-check mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="check1"
                      checked={onlineAvailable}
                      onChange={handleOnlineChange}
                    />
                    <label className="form-check-label" htmlFor="check1">
                      Online Appointments
                    </label>
                  </div>

                  {/* Offline Checkbox */}
                  <div className="form-check mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="check2"
                      checked={offlineAvailable}
                      onChange={handleOfflineChange}
                    />
                    <label className="form-check-label" htmlFor="check2">
                      Offline Appointments
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Col>
    </div>
  );
}

export default Availability;
