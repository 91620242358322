import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import DummyUser from "../../images/upload.png";
import addFileToS3Bucket from "../../services/Aws";
import LoadingImage from "../../images/loading-image.webp";
import toast from "react-hot-toast";
import { ReAttachDocuApi } from "../../services/DashboardAsyncFunctions";

function ReAttactDocu({ show, onHide, setRequestType, getDashboardData }) {
  const imageRefResume = useRef();
  const imageRefGov = useRef();
  const imageRefDegree = useRef();
  const [resumeImageUrl, setResumeImageUrl] = useState(null);
  const [govImageUrl, setGovImageUrl] = useState(null);
  const [degreeImageUrl, setDegreeImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [imageResumeUploadLoading, setImageResumeUploadLoading] =
    useState(false);
  const [imageDegreeUploadLoading, setImageDegreeUploadLoading] =
    useState(false);
  const [imageGovUploadLoading, setImageGovUploadLoading] = useState(false);

  async function handleResumeImageUpload(files) {
    if (!files?.[0]) return;
    console.log(files);
    setImageResumeUploadLoading(true);
    try {
      let response = await addFileToS3Bucket(files[0], "resume");
      setResumeImageUrl(response.Location);
    } catch (error) {
      toast.error("Error occured while uploading media!");
      setResumeImageUrl(null);
    } finally {
      setImageResumeUploadLoading(false);
    }
  }

  async function handleGovImageUpload(files) {
    if (!files?.[0]) return;
    console.log(files);
    setImageGovUploadLoading(true);
    try {
      let response = await addFileToS3Bucket(files[0], "gov");
      setGovImageUrl(response.Location);
    } catch (error) {
      toast.error("Error occured while uploading media!");
      setGovImageUrl(null);
    } finally {
      setImageGovUploadLoading(false);
    }
  }

  async function handleDegreeImageUpload(files) {
    if (!files?.[0]) return;
    console.log(files);
    setImageDegreeUploadLoading(true);
    try {
      let response = await addFileToS3Bucket(files[0], "degree");
      setDegreeImageUrl(response.Location);
    } catch (error) {
      toast.error("Error occured while uploading media!");
      setDegreeImageUrl(null);
    } finally {
      setImageDegreeUploadLoading(false);
    }
  }

  const handleSubmit = async () => {
    if (
      resumeImageUrl == null ||
      resumeImageUrl == "" ||
      govImageUrl == null ||
      govImageUrl == "" ||
      degreeImageUrl == null ||
      degreeImageUrl == ""
    ) {
      toast.error("All doucments are required!");
    } else {
      const formData = {
        governmentId: govImageUrl,
        resume: resumeImageUrl,
        degree: degreeImageUrl,
      };

      setIsLoading(true);

      try {
        let response = await ReAttachDocuApi(formData);
        if (response.status === 200) {
          toast.success("Re upload successful!", { duration: 5000 });
          getDashboardData();
          onHide();
        }
      } catch (error) {
        if (error.response.data.data === "image must be a string") {
          toast.error("Image is required!");
        } else {
          toast.error(error.response?.data?.data);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        className="modal fade"
        backdrop="static"
        centered
        onHide={onHide}
        show={show}
      >
        <div className="animate__animated animate__fadeIn p-3" role="document">
          <Modal.Header>
            <h5 className="">Re Upload Document</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group doument-upload">
              <div>
                <label htmlFor="email">Resume</label>
                <div className="relative">
                  {!imageResumeUploadLoading && (
                    <img
                      src={resumeImageUrl ? resumeImageUrl : DummyUser}
                      width={100}
                      height={100}
                      className="signup-img"
                    />
                  )}
                  {imageResumeUploadLoading && (
                    <img
                      src={LoadingImage}
                      width={100}
                      height={100}
                      className="signup-img"
                    />
                  )}

                  <input
                    ref={imageRefResume}
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={(e) => handleResumeImageUpload(e.target.files)}
                  />
                  <i
                    className="flaticon-381-edit absolute edit-img-icon"
                    onClick={() => imageRefResume.current.click()}
                  ></i>
                </div>
              </div>

              <div>
                <label htmlFor="email">Government Id</label>
                <div className="relative">
                  {!imageGovUploadLoading && (
                    <img
                      src={govImageUrl ? govImageUrl : DummyUser}
                      width={100}
                      height={100}
                      className="signup-img"
                    />
                  )}
                  {imageGovUploadLoading && (
                    <img
                      src={LoadingImage}
                      width={100}
                      height={100}
                      className="signup-img"
                    />
                  )}

                  <input
                    ref={imageRefGov}
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={(e) => handleGovImageUpload(e.target.files)}
                  />
                  <i
                    className="flaticon-381-edit absolute edit-img-icon"
                    onClick={() => imageRefGov.current.click()}
                  ></i>
                </div>
              </div>

              <div>
                <label htmlFor="email">Degree</label>
                <div className="relative">
                  {!imageDegreeUploadLoading && (
                    <img
                      src={degreeImageUrl ? degreeImageUrl : DummyUser}
                      width={100}
                      height={100}
                      className="signup-img"
                    />
                  )}
                  {imageDegreeUploadLoading && (
                    <img
                      src={LoadingImage}
                      width={100}
                      height={100}
                      className="signup-img"
                    />
                  )}

                  <input
                    ref={imageRefDegree}
                    type="file"
                    accept="image/*"
                    className="d-none"
                    onChange={(e) => handleDegreeImageUpload(e.target.files)}
                  />
                  <i
                    className="flaticon-381-edit absolute edit-img-icon"
                    onClick={() => imageRefDegree.current.click()}
                  ></i>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className=" d-flex align-items-center">
            <button
              type="button"
              className="btn btn-warning bg-danger flex-grow-1"
              onClick={() => {
                onHide();
                setRequestType();
              }}
            >
              <i className="flaticon-delete-1"></i> Cancel
            </button>
            <button
              type="button"
              disabled={isLoading}
              className="btn btn-success flex-grow-1"
              onClick={handleSubmit}
            >
              {isLoading ? "Processing..." : "Re attach Documents"}
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(ReAttactDocu);
